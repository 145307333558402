import axios from '@axios'

const route = 'debug'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    dailyReport(filtro){
      return axios.post(`${route}/daily_report`, filtro)
    }
   
  },
}
